import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PersonalSerialRedirection = () => {
	const { b2c_user_id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUsernameAndRedirect = async () => {
			try {
				// Fetch the username based on the B2CUserId
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/b2c-user/username/${b2c_user_id}`);
				const username = response.data.data;

				// Redirect to /p/:username
				navigate(`/p/${username}`, { replace: true });
			} catch (err) {
				console.error('Error fetching username:', err);
				// Handle error
			}
		};

		fetchUsernameAndRedirect();
	}, [b2c_user_id, navigate]);

	return (
		<div></div>
	);
};

export default PersonalSerialRedirection;