import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const CorporateSerialRedirection = () => {
	const { company_uid, company_user_id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUsernameAndRedirect = async () => {
			try {
				// Fetch the username based on the companyUserId
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/b2b-user/username/${company_uid}/${company_user_id}`);
				const username = response.data.data;

				// Redirect to /:companyUID/:username
				navigate(`/${company_uid}/p/${username}`, { replace: true });
			} catch (err) {
				console.error('Error fetching username:', err);
			}
		};

		if(!Number.isNaN(company_user_id)) {
			navigate(`/${company_uid}/p/${company_user_id}`, { replace: true });
		}

		fetchUsernameAndRedirect();
	}, [company_uid, company_user_id, navigate]);
	return (
		<div></div>
	);
};

export default CorporateSerialRedirection;