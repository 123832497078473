// RedirectExternal.tsx
import { useEffect } from 'react';

interface RedirectExternalProps {
	to: string;
}

const RedirectExternal: React.FC<RedirectExternalProps> = ({ to }) => {
	useEffect(() => {
		window.location.href = to;
		// Alternatively, use window.location.replace(to);
	}, [to]);

	return null;
};

export default RedirectExternal;
